<template>
  <div>
    <div class="customizer" :class="{ open: isOpen }">
      <div class="handle" @click="isOpen = !isOpen">
        <i class="i-Gear"></i>
      </div>

      <vue-perfect-scrollbar
        :settings="{ suppressScrollX: true, wheelPropagation: false }"
        class="customizer-body ps rtl-ps-none"
      >
        <div class="">
          <div class="card-header" id="headingOne">
            <p class="mb-0">
              {{$t("settings.title")}}
            </p>
          </div>
        </div>

        <div class="">
          <div class="card-header">
            <p class="mb-0">
              {{$t("settings.darkmode")}}
            </p>
          </div>

          <div class="card-body">
            <label
              class="switch switch-primary mr-3 mt-2"
              v-b-popover.hover.left="$t('settings.darkmode')"
            >
              <input type="checkbox" @click="changeThemeMode" />
              <span class="slider"></span>
            </label>
          </div>
        </div>
        <div class="">
          <div class="card-header">
            <p class="mb-0">
              {{$t("settings.language")}}
            </p>
          </div>

          <div class="card-body">
            <b-button variant="default m-1" @click="changeLang('en')"
              ><flag iso="US"
            /></b-button>
            <b-button variant="default m-1" @click="changeLang('nl')"
              ><flag iso="NL"
            /></b-button>
          </div>
        </div>
      </vue-perfect-scrollbar>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      isOpen: false
    };
  },

  computed: {
    ...mapGetters(["getThemeMode", "getcompactLeftSideBarBgColor"])
  },

  i18n: {
    messages: {
      en: {
        settings: {
          title: "Settings",
          darkmode: "Switch on dark mode?",
          language: "Switch to another language?"
        }
      },
      nl: {

      }
    }
  },

  methods: {
    ...mapActions([
      "changeThemeRtl",
      "changeThemeLayout",
      "changeThemeMode",
      "changecompactLeftSideBarBgColor"
    ]),
    changeLang(lang) {
      console.log(lang);
      this.$i18n.locale = lang;
      this.$toast.success("Language changed.");
    }
  }
};
</script>

<style lang="scss" scoped>
</style>